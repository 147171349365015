import request from '@/utils/request'

const addMyVip = (schoolId, params) => {
  return request({
    url: `/school/schools/${schoolId}/users`,
    data: params,
    method: 'post'
  })
}

const getVipList = (schoolId, params) => {
  return request({
    url: `/school/schools/${schoolId}/users`,
    params,
    method: 'get'
  })
}

const getVipListLength = (schoolId, params) => {
  return request({
    url: `/school/schools/${schoolId}/users/count`,
    params,
    method: 'get'
  })
}

const getSmsTemplateList = (schoolId, params) => {
  return request({
    url: `/school/schools/${schoolId}/users/sms/list`,
    params,
    method: 'get'
  })
}

const deleteVip = (schoolId, id) => {
  return request({
    url: `/school/schools/${schoolId}/users/${id}`,
    method: 'delete'
  })
}

const modifyVip = (schoolId, id, params) => {
  return request({
    url: `/school/schools/${schoolId}/users/${id}`,
    data: params,
    method: 'put'
  })
}

const getVipInfo = (schoolId, id) => {
  return request({
    url: `/school/schools/${schoolId}/users/${id}`,
    mothod: 'get'
  })
}

const importUser = (schoolId, params) => {
  return request({
    url: `/school/schools/${schoolId}/users/import`,
    data: params,
    method: 'post'
  })
}
const importUserHeaders = (schoolId, params) => {
  return request({
    url: `/school/schools/${schoolId}/users/import/headers`,
    data: params,
    method: 'post'
  })
}

// 获取店铺列表
const getStoreList = (storeId, params) => {
  return request({
    url: `/school/stores/${storeId}/uesrs`,
    params,
    method: 'get'
  })
}

// 获取店铺管理员列表数量
const getStoreLength = (storeId, params) => {
  return request({
    url: `/school/stores/${storeId}/uesrs/count`,
    params,
    method: 'get'
  })
}

// 创建店铺管理员
const createStore = (storeId, params) => {
  return request({
    url: `/school/stores/${storeId}/uesrs`,
    data: params,
    method: 'post'
  })
}
// 移除店铺用户
const deleteStore = (storeId, userId) => {
  return request({
    url: `/school/stores/${storeId}/uesrs/${userId}`,
    method: 'delete'
  })
}

// 获取导入进度处理
const getImportProgress = (schoolId, params) => {
  return request({
    url: `/school/schools/${schoolId}/users/import`,
    params,
    method: 'get'
  })
}
// 停用教师账号
const outTeacherService = (id, params) => {
  return request({
    url: `/auth/user/${id}/ban`,
    data: params,
    method: 'put'
  })
}
export {
  addMyVip,
  getVipList,
  getVipListLength,
  deleteVip,
  modifyVip, // 修改会员信息
  getVipInfo, // 获取用户信息
  importUser, // 上传用户数据，excel
  importUserHeaders, // 上传用户头像 zip
  getImportProgress,
  /* getSupertubeList,
  getSupertubeLength,
  createSupertuber,
  deleteSupertuber,
  getChargeLength,
  getChargeList,
  createCharger,
  deleteCharger, */
  getStoreList,
  getStoreLength,
  createStore,
  deleteStore,
  outTeacherService,
  getSmsTemplateList
}
